import { createMemo } from 'solid-js';
import { CurrencyCode, RoundHolesOption } from '../graphql';

export const dollarFormatter = (currency: CurrencyCode = CurrencyCode.Usd) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
		currencyDisplay: 'narrowSymbol',
	}).format;
};

export const createDollarFormatter = (locale: string = 'en-US', currency: CurrencyCode = CurrencyCode.Usd) =>
	createMemo(
		() =>
			new Intl.NumberFormat(locale, {
				style: 'currency',
				currency,
				currencyDisplay: 'narrowSymbol',
				trailingZeroDisplay: 'stripIfInteger',
			}),
	);

export const createNumberFormatter = (locale: string = 'en-US') =>
	createMemo(() => {
		return new Intl.NumberFormat(locale, {
			style: 'decimal',
			trailingZeroDisplay: 'stripIfInteger',
		});
	});

export function holesFormatter(formatter: Intl.NumberFormat, holes: RoundHolesOption) {
	const num = roundHolesToInt[holes];
	if (Array.isArray(num)) {
		return formatter.formatRange(num[0], num[1]);
	}
	return formatter.format(num);
}

const roundHolesToInt: Record<RoundHolesOption, number | [number, number]> = {
	[RoundHolesOption.Eighteen]: 18,
	[RoundHolesOption.Nine]: 9,
	[RoundHolesOption.NineOrEighteen]: [9, 18],
};
